<template>
  <n-bottom-sheet
    v-if="sheetVisible"
    ref="sheet"
    no-layout
    class="sheet"
    :top="top"
    :start="top"
    :bottom="bottom"
  >
    <n-layout
      :spacing-y="false"
      :spacing-bottom="true"
    >
      <n-grid :top-gap="0">
        <template v-if="isFullyBooked">
          <n-text
            class="span-6"
            preset="header"
            color="accent"
          >
            {{ $t('main.activeTrip.activeTripSheet.fullyBookedHeader') }}
          </n-text>

          <n-text
            class="span-6"
            preset="body"
            color="grey-dark"
          >
            {{ $t('main.activeTrip.activeTripSheet.fullyBookedDescription') }}
          </n-text>
        </template>
        <template v-else-if="locationMissingAndCodeExpired && !awaitingLocation">
          <n-text
            class="span-6"
            preset="header"
            color="accent"
          >
            {{ $t('main.activeTrip.activeTripSheet.locationMissingHeader') }}
          </n-text>

          <n-text
            class="span-6"
            preset="body"
            color="grey-dark"
          >
            {{ $t('main.activeTrip.activeTripSheet.locationMissingDescription') }}
          </n-text>

          <n-button
            v-if="locationPermissionGranted"
            :loading="loadingCode"
            block
            type="filled"
            color="default"
            @click="requestCodeRenewal"
          >
            {{ $t('main.activeTrip.activeTripSheet.locationMissingButton') }}
          </n-button>
          <n-button
            v-else
            :loading="loadingCode"
            block
            type="filled"
            color="default"
            @click="openAppSettings"
          >
            {{ $t('main.activeTrip.activeTripSheet.locationPermissionMissingButton') }}
          </n-button>
        </template>

        <template v-else-if="internetMissing">
          <n-text
            class="span-6"
            preset="header"
            color="accent"
          >
            {{ $t('main.activeTrip.activeTripSheet.internetMissingHeader') }}
          </n-text>

          <n-text
            class="span-6"
            preset="body"
            color="grey-dark"
          >
            {{ $t('main.activeTrip.activeTripSheet.internetMissingDescription') }}
          </n-text>

          <trip-code-button
            :loading="loadingCode"
            @retry="requestCodeRenewal"
          >
            {{ $t('main.activeTrip.activeTripSheet.internetMissingButton') }}
          </trip-code-button>
        </template>

        <template v-else>
          <n-grid>
            <n-column :span="hasHelpText ? 5 : 6">
              <n-text
                preset="header"
                color="accent"
              >
                {{ $t('main.activeTrip.activeTripSheet.header') }}
              </n-text>
            </n-column>

            <n-column
              v-if="hasHelpText"
              class="help-container"
              :span="1"
            >
              <n-help
                :text="$t('main.activeTrip.activeTripSheet.helpText')"
                dialog-name="active-trip-dialog"
              />
            </n-column>
          </n-grid>

          <n-text
            class="span-6"
            preset="body"
            color="grey-dark"
          >
            {{ $t('main.activeTrip.activeTripSheet.description') }}
          </n-text>

          <n-grid
            v-if="nearDestination"
            :y-gap="5"
          >
            <n-text
              class="span-6"
              preset="body"
              color="accent-faded"
              align="center"
            >
              {{ $t('main.activeTrip.activeTripSheet.nearDestination', {
                meters: nearDestinationMeters
              }) }}
            </n-text>
          </n-grid>

          <trip-code
            v-if="!nearDestination"
            :renews-at="code.renews_at"
            :loading="!hasValidCode() && (loadingCode || awaitingLocation)"
            :code="code.value"
            :link="code.link"
            @renew="requestCodeRenewal"
          />

          <n-button
            block
            type="outlined"
            color="default"
            inverted
            @click="onBoardPassengersClick"
          >
            {{ $t('main.activeTrip.activeTripSheet.boardPassengersSheetButton') }}
          </n-button>
        </template>
      </n-grid>

      <parking-section
        v-if="shouldShowParkingSection"
        :reservation="parkingReservation"
        display-type="active-trip"
        :has-available-parking-spots="Boolean(hasAvailableParkingSpots)"
        :parking-site-name="parkingSiteName"
        :partner-logo="partnerLogo"
        @bookParkingSpot="bookParkingSpot"
        @releaseParkingSpot="releaseParkingSpot"
      />

      <parking-eligibility-information
        v-if="shouldShowParkingInformation"
        :parking-site-name="parkingSiteName"
      />

      <n-grid
        v-if="tripExists"
        class="passenger-trips"
      >
        <template v-if="activePassengerTrips.length > 0 || cancelledPassengerTrips.length === 0">
          <n-seperator class="span-6 seperator" />

          <n-text
            class="span-6"
            preset="body"
            color="accent"
          >
            {{
              $t('main.activeTrip.activeTripSheet.passengerOverviewTitle', {
                booked: activePassengerTrips.length,
                seats: seats,
                income: $n(income, 'currency', profile.currency),
              })
            }}
          </n-text>

          <active-trip-passenger-trip
            v-for="(passengerTrip, index) in activePassengerTrips"
            :key="passengerTrip.id"
            :passenger-trip="passengerTrip"
            :last-child="index === activePassengerTrips.length - 1"
            @cancel="confirmCancelPassengerTrip"
          />
        </template>

        <template v-if="showSendMessageToAllButton">
          <n-grid :top-gap="4">
            <n-button
              block
              type="outlined"
              color="default"
              inverted
              @click="openBroadcastSheet"
            >
              {{ $t('main.activeTrip.sendMessageToAllPassengersButton') }}
            </n-button>
          </n-grid>
        </template>

        <template v-if="cancelledPassengerTrips.length > 0">
          <n-seperator class="span-6 seperator" />

          <n-text
            class="span-6"
            preset="body"
            color="error"
          >
            {{ $t('main.activeTrip.activeTripSheet.cancellations') }}
          </n-text>

          <active-trip-passenger-trip
            v-for="(passengerTrip, index) in cancelledPassengerTrips"
            :key="passengerTrip.id"
            :passenger-trip="passengerTrip"
            :last-child="index === cancelledPassengerTrips.length - 1"
          />
        </template>
      </n-grid>
    </n-layout>

    <broadcast-message-to-passenger-sheet
      v-if="activeDriverTrip !== null"
      ref="broadcastMessageToPassengerSheet"
      :trip-id="activeDriverTrip.id"
      :passengers="activeDriverTrip.passenger_trips"
    />
    <cancel-passenger-trip-dialog ref="cancelPassengerTripDialog" />

    <n-dialog />
  </n-bottom-sheet>
</template>

<script>
import { permission } from '@/constants';
import { EventBus } from '@/vendor/events';
import { defaultSeats } from '@/constants';
import { compareDesc } from 'date-fns';
import config from '@shared/config.json';
import TripCode from '@/components/shared/tripCode';
import { mapActions, mapState, mapMutations } from 'vuex';
import ActiveTripPassengerTrip from './activeTripPassengerTrip';
import { isLocationWithinLifetime } from './support/location';
import TripCodeButton from '@/components/shared/tripCodeButton';
import CancelPassengerTripDialog from '@/dialogs/cancelPassengerTripDialog';
import BroadcastMessageToPassengerSheet from '@/sheets/broadcastMessageToPassengerSheet';
import ParkingSection from '@/components/shared/parking/parkingSection';
import commuteApi from '@/api/commute';
import ParkingEligibilityInformation from '@/components/shared/parking/parkingEligibilityInformation.vue';

export default {
  name: 'MainActiveTripSheet',
  components: {
    TripCode,
    TripCodeButton,
    ActiveTripPassengerTrip,
    CancelPassengerTripDialog,
    BroadcastMessageToPassengerSheet,
    ParkingSection,
    ParkingEligibilityInformation
  },
  props: {
    awaitingLocation: {
      type: Boolean,
      default: false,
    },
    locationMissing: {
      type: Boolean,
      default: true,
    },
    internetMissing: {
      type: Boolean,
      default: false,
    },
    nearDestination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingCode: false,
      locationLifetimeMonitor: null,
      codeLifetimeMonitorFailed: false,
      locationPermissionGranted: null,
      passengerJoinedTripListener: null,
      parkingSpot: null,
      eligibleForParking: false,
      hasAvailableParkingSpots: false,
      parkingReservation: null,
      parkingSiteName: null,
      partnerLogo: null,
    };
  },
  computed: {
    ...mapState('app', ['edges']),
    ...mapState('user', ['profile']),
    ...mapState('trip', [
      'activeDriverTrip',
      'location',
      'locationUpdatedAt',
      'code',
    ]),

    top() {
      return 90 + (this.edges && this.edges.top ? this.edges.top - 20 : 0);
    },
    bottom() {
      return window.innerHeight - 200;
    },

    hasHelpText() {
      return !!this.$t('main.activeTrip.activeTripSheet.helpText');
    },
    sheetVisible() {
      return true;
    },
    showSendMessageToAllButton() {
      return this.activePassengerTrips.some((passengerTrip) => passengerTrip.user.has_mandatory_properties);
    },
    activePassengerTrips() {
      return (
        this.activeDriverTrip?.passenger_trips?.filter(
          (passengerTrip) => passengerTrip.status === 'ACCEPTED'
        ) || []
      );
    },
    hasAcceptedPassengersWithContract() {
      return this.activePassengerTrips.some(
        (passengerTrip) => passengerTrip.has_contract
      );
    },
    cancelledPassengerTrips() {
      return (
        this.activeDriverTrip?.passenger_trips
          ?.filter(
            (passengerTrip) => passengerTrip.status === 'CANCELED'
          )
          ?.sort((a, b) => {
            return compareDesc(
              new Date(a.cancelled_at),
              new Date(b.cancelled_at)
            );
          }) || []
      );
    },

    tripExists() {
      return !!this.activeDriverTrip?.id;
    },
    nearDestinationMeters() {
      return config.on_demand.subsidy_distance_meters;
    },
    seats() {
      return defaultSeats;
    },
    income() {
      return this.activePassengerTrips
        .reduce((sum, passengerTrip) => {
          return sum + passengerTrip.payout;
        }, 0)
    },
    currency() {
      return this.profile?.currency;
    },
    locationMissingAndCodeExpired() {
      return this.locationMissing && this.codeLifetimeMonitorFailed;
    },
    isFullyBooked() {
      const bookedSeats = this.activePassengerTrips
        .reduce((sum, passengerTrip) => {
          return sum + (passengerTrip.seats || 1);
        }, 0);

      return bookedSeats === 4;
    },
    hasPassengersWithContract() {
      return this.activeDriverTrip?.passenger_trips?.some(
        (passengerTrip) => passengerTrip.has_contract
      );
    },
    shouldShowParkingSection() {
      if(this.parkingReservation){
        return true;
      }

      if(this.eligibleForParking){
        return true;
      }

      return false;
    },
    shouldShowParkingInformation() {
      if(this.parkingReservation){
        return false;
      }

      if(this.eligibleForParking){
        return false;
      }

      if(!this.parkingSiteName){
        return false;
      }

      return true;
    }
  },
  watch: {
    sheetVisible: {
      handler(visible) {
        this.$emit('sheet-visibility-change', visible);
      },
      immediate: true,
    },
    locationMissingAndCodeExpired(value, oldValue) {
      if (value && !oldValue) {
        this.$emit('retrieve-location');
      }
    },
    activePassengerTrips(value, oldValue) {
      if (value.length !== oldValue.length) {
        this.fetchParkingStatus();
      }
    },
    activeDriverTrip(value, oldValue) {
      if (value && value.parking_site_name) {
        this.parkingSiteName = value.parking_site_name;
      }
    }
  },
  mounted() {
    this.lifetimeMonitor = setInterval(() => {
      this.monitorCodeLifetime();
      this.monitorLocationLifetime();
    }, 500);

    EventBus.$on('location', this.onLocationUpdate);
    EventBus.$on('checkPermissionResult', ({ name, granted }) => {
      if (name === permission.LOCATION) {
        this.locationPermissionGranted = granted;
      }
    });

    this.refreshActiveTrip();
    this.fetchParkingStatus();
  },
  beforeDestroy() {
    clearInterval(this.lifetimeMonitor);
    EventBus.$off('location', this.onLocationUpdate);
  },
  methods: {
    ...mapActions('trip', ['renewCode', 'refreshActiveTrip', 'cancelPassengerTrip']),
    ...mapMutations('trip', [
      'invalidateLocation',
      'invalidateCode',
      'setCode',
    ]),
    async fetchParkingStatus() {
      if (!this.activeDriverTrip?.passenger_trips?.some(
        (passengerTrip) => passengerTrip.has_contract)
      ) {
        return;
      }

      const { eligible, reservation, hasAvailableSpots, parkingSiteName, partnerLogo } = await commuteApi.getParkingSpot(this.activeDriverTrip.id);
      this.eligibleForParking = eligible;
      this.hasAvailableParkingSpots = Boolean(hasAvailableSpots);
      this.parkingReservation = reservation;
      this.parkingSiteName = parkingSiteName;
      this.partnerLogo = partnerLogo;

      if (reservation) {
        EventBus.$emit('parkingSpotUpdate');
      }

    },
    async releaseParkingSpot() {
      await commuteApi.releaseParking(this.activeDriverTrip.id);
      this.$success(this.$t('main.parkingSection.parkingSpotReleased'));
      this.fetchParkingStatus();
      EventBus.$emit('parkingSpotUpdate');
    },
    async bookParkingSpot(data) {
      try {
        await commuteApi.bookParking(this.activeDriverTrip.id, {
          license_plate: data.licensePlate,
          duration: data.duration
        });
        this.$success(this.$t('main.parkingSection.parkingSpotReserved'));

        EventBus.$emit('parkingSpotUpdate');

      } catch (error) {
        this.$error(error.response?.data?.message || 'Failed to book parking spot');
      } finally {
        this.fetchParkingStatus();
      }
    },
    checkLocationPermission() {
      window.sendNative.checkPermission(permission.LOCATION);
    },
    onBoardPassengersClick() {
      EventBus.$emit('board-passengers-sheet-trigger');
    },
    onLocationUpdate(location) {
      this.monitorCodeLifetime();
    },
    openAppSettings() {
      window.sendNative.openAppSettings();
    },
    hasValidCode() {
      if (!this.code?.renews_at) {
        return false;
      }

      const renewsAt = new Date(this.code.renews_at);

      return new Date() < renewsAt;
    },
    monitorCodeLifetime() {
      const hasValidCode = this.hasValidCode();

      this.codeLifetimeMonitorFailed = !hasValidCode;
    },
    hasRecentLocation() {
      if (!this.location || !this.locationUpdatedAt) {
        return false;
      }

      return isLocationWithinLifetime(this.locationUpdatedAt);
    },
    monitorLocationLifetime() {
      if (!this.hasRecentLocation() && !this.locationMissingAndCodeExpired) {
        this.invalidateLocation();
      }
    },
    async confirmCancelPassengerTrip(passengerTrip) {
      this.$modal.show('active-trip-dialog', {
        title: this.$t('main.activeTrip.activeTripSheet.cancelAgreementConfirmTitle'),
        text: this.$t('main.activeTrip.activeTripSheet.cancelAgreementConfirmDescription'),
        color: 'error',
        cancel: true,
        success: {
          text: this.$t('tripDetails.passenger.cancelDialog.successButton'),
          handler: async () => {
            await this.cancelPassengerTrip(
              passengerTrip.id
            );
            await this.fetchParkingStatus();
            await this.refreshActiveTrip();
          },
        },
      });
    },
    async requestCodeWhenMissing() {
      if (!this.hasValidCode()) {
        await this.requestCodeRenewal();
      }
    },
    async requestCodeRenewal() {
      if (this.loadingCode || !this.hasRecentLocation()) {
        this.checkLocationPermission();
        return;
      }

      this.loadingCode = true;
      this.renewCode()
        .then(() => {
          this.$emit('near-destination-change', false);

          this.monitorCodeLifetime();
          this.refreshActiveTrip();

          this.$emit('internet-missing-change', false);
        })
        .catch((e) => {
          if (e?.message !== 'no_active_trip') {
            this.$emit('internet-missing-change', typeof e?.response === 'undefined');
          }

          if (e?.response?.data?.identifier === 'no_recent_position') {
            this.invalidateLocation();
          }

          if (e?.response?.data?.identifier === 'near_destination') {
            this.$emit('near-destination-change', true);
          }
        })
        .finally(() => {
          // wait at least 1 second before allowing another request
          setTimeout(() => {
            this.loadingCode = false;
          }, 1000);
        });
    },
    openBroadcastSheet() {
      this.$refs.broadcastMessageToPassengerSheet.openSheet();
    },
  },
};
</script>

<style lang="scss" scoped>
.help-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 0.25rem;

  --icon-md-size: 1.75rem;
}

.seperator {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.passenger-trips {
  margin-bottom: 5rem !important;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-top {
  margin-top: 1rem;
}
</style>
