<template>
  <div
    class="parking-header"
    :class="{ 'parking-header-with-info': showInfo }"
  >
    <div class="parking-header__text">
      <div class="title-container">
        <n-text
          preset="header-medium"
          color="blue-neutral"
        >
          {{ $t('main.parkingSection.title') }} 
        </n-text>
        <n-icon
          v-if="showInfo"
          name="info-large"
          @click="$emit('info-click')"
        />
      </div>
      <n-text 
        v-if="licensePlate && isOverview"
        preset="body"
        color="neutral"
        class="license-plate-text"
      >
        {{ $t('main.parkingSection.subtitle') }} <b>{{ licensePlate }}</b>
      </n-text>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParkingSectionHeader',
  props: {
    showInfo: {
      type: Boolean,
      default: false
    },
    licensePlate: {
      type: String,
      default: ''
    },
    isOverview: {
      type: Boolean,
      default: false
    }
  },
  emits: ['info-click']
}
</script>

<style lang="scss" scoped>
.parking-header {
  margin-bottom: 16px;

  &-with-info {
    margin-bottom: 24px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .license-plate-text {
    margin-top: 4px;
  }
}
</style>